@font-face {
	font-family: BitstreamRoman;
	src: url("./assets/Fonts/BitstreamRoman.ttf");
}

@font-face {
	font-family: BitstreamRomanaBold;
	src: url("./assets/Fonts/Bitstream - Romana BT Bold.ttf");
}

@font-face {
	font-family: piiqType;
	src: url("./assets/Fonts/gerstnerprogrammfsl-regular-webfont.woff");
	unicode-range: U+0-2f, U+40-10FFFF;
	letter-spacing: 0.5px !important;
}
@font-face {
	font-family: piiqTypeSerif;
	src: "Times New Roman", "Times", serif;
	unicode-range: U+30-39;
	letter-spacing: 1px !important;
}

html,
body,
#root {
	margin: 0;
	padding: 0;
	height: 100%;
	font-family: piiqType;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #ffffff !important;
	overflow-x: hidden;
}

::-webkit-scrollbar {
	width: 4px;
	background-color: #f5f5f5;
}
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
	background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
	background-color: #000000;
	border: 2px solid #555555;
}

h1 {
	font-family: piiqType;
	text-transform: uppercase;
	font-weight: normal;
}
h2,
h3 {
	font-weight: normal;
}
#root {
	height: 100%;
}
