@font-face {
  font-family: titleFont;
  src: url("./../../assets/Fonts/BitstreamRoman.ttf");
}

.centerContent {
  width: 100%;
  text-align: center;
}

h1 {
  font-size: 60px;
  font-family: titleFont;
  text-transform: none;
  margin: 24px 0 20px 0;
}

p {
  font-size: 16px;
}

input:-webkit-autofill {
  background-color: transparent !important;
  font-size: 16px;
}
input:autofill {
  font-size: 16px;
  background-color: transparent !important;
}

.opacity-enter {
  opacity: 0;
}
.opacity-enter-active {
  opacity: 1;
  transition: all 800ms;
}
.opacity-enter-done {
  opacity: 1;
}
.opacity-exit {
  opacity: 1;
}
.opacity-exit-active {
  opacity: 0;
  transition: all 800ms;
}
.opacity-exit-done {
  opacity: 0;
}