@font-face {
  font-family: titleFont;
  src: url("./../../assets/Fonts/BitstreamRoman.ttf");
}

#logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  height: 100%;
}

#auth-card {
  box-shadow: none;
}
#auth-card form {
  min-width: 300px;
  max-width: 350px;
}

#auth-card p {
  text-transform: uppercase;
  font-size: 1.6rem;
  margin-left: 15px;
  margin-top: -10px;
  margin: auto;
  width: 81%;
}

#termsAndCondition {
  position: absolute;
  bottom: 20px;
}

#login-img {
  width: 100%;
  height: 100vh;
  background: url(../../assets/Images/loginBackground.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.centerContent {
  width: 100%;
  text-align: center;
}
